import React from 'react'
import { connect } from 'react-redux'
import app_config from '../../config/app/config'
import { WithTranslation, withTranslation } from 'react-i18next'

import { mapDispatchToProps } from '../../libs'
import { apiLog } from '../../api/restApi'

import PageSocketManager, { PageSocketManagerProps } from '../PageSocketManager'
import { GlobalError } from './GlobalError'
import { errorNotification } from '../Notification/notifications'

const handledTypes = Object.values(app_config.errorTypes)

type OwnProps = Record<string, unknown>

interface ConnectedProps {
  actions: {
    storeError: (error: any) => void
    removeError?: () => void
  }
  socketIoEvent?: string
}

type Props = OwnProps & ConnectedProps & WithTranslation & PageSocketManagerProps

class ErrorBoundary extends React.Component<Props> {
  componentDidUpdate(prevProps: ConnectedProps) {
    const { socketIoEvent } = this.props

    if (prevProps.socketIoEvent !== socketIoEvent && socketIoEvent === 'do_clear_storage') {
      localStorage.clear()
      window.location.reload()
    }
  }

  componentDidCatch(error: any) {
    const isCustomError =
      error.type && (handledTypes.includes(error.type) || typeof error.type === 'object')

    apiLog(error)

    if (isCustomError) {
      this.handleCustomError(error)
    } else {
      this.props.actions.storeError(error)
    }
  }

  handleCustomError = (error: any) => {
    const { t } = this.props

    errorNotification({
      message: t(error.type.message || error.type),
    })
  }

  render() {
    const { children } = this.props

    return <GlobalError>{children}</GlobalError>
  }
}

function mapStateToProps(): Omit<ConnectedProps, 'actions'> {
  return {}
}

const ConnectedErrorBoundary = connect(mapStateToProps, mapDispatchToProps())(ErrorBoundary)

const ErrorBoundaryManager = PageSocketManager(ConnectedErrorBoundary, ['do_clear_storage'])

export default withTranslation('common')(ErrorBoundaryManager) as React.ComponentType<OwnProps>
