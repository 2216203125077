import { forOwn, uniq } from 'lodash'
import { MutableRefObject } from 'react'

import { VirtualMirrorCatalogue } from '@luxottica/virtual-mirror'

import app_config from '../config/app/config'
import { Brand, SubBrand } from '../model/brand'
import { RcEvent } from '../model/events'
import { Moco, Product, QueryParams, Size } from '../model/product'
import { breakpointsNoUnit } from '../style/theme'
import { createBrandsQueryParams } from './brand'
import { getActiveFilters } from './filters'
import { isIPadView } from './url'

/**
 * Checks each couvette's moco if Virtual Mirror is available for it
 * @returns Promise resolved with object having moco's codes as keys and boolean values of VM availalility
 */

export const checkVirtualMirrorAvailability = (mocos: Record<string, Moco>) => {
  const VMCatalogue = VirtualMirrorCatalogue.build({
    key: '0408c2cc-035b-4924-ae3c-4a5d4e55d54d',
  })

  const promises = Object.values(mocos).map((moco: Moco) =>
    Object.values(moco.sizes).map((size: Size) => {
      const result = VMCatalogue.isUpcSupported(size.upc)
      return result[size.upc]
        .then(response => ({
          code: moco.mocoCode,
          isAvailable: response.isAvailable(),
          upc: size.upc,
        }))
        .catch(() => ({ [moco.mocoCode]: false }))
    }),
  )

  const promisesFlattened = promises.flat()

  return Promise.all(promisesFlattened).then(mocosAvailability => {
    const uniqMocoCodes = [...new Set(mocosAvailability.map(q => q.code))]
    const formattedMocos: any[] = []

    uniqMocoCodes.forEach(u => {
      let itemAvailable = mocosAvailability.find(m => m.code === u && m.isAvailable === true)
      if (itemAvailable) {
        formattedMocos.push(itemAvailable)
        return
      }

      itemAvailable = mocosAvailability.find(m => m.code === u)
      formattedMocos.push({ code: u, isAvailable: false, upc: itemAvailable?.upc })
    })

    const res = formattedMocos.reduce((acc, cur) => {
      const curValue = {
        [cur.code]: {
          isAvailable: cur.isAvailable,
          upc: cur.upc,
        },
      }
      return { ...acc, ...curValue }
    }, {})

    return res
  })
}

export const isCategorySun = (category: string) => {
  return category === 'sun' || category === app_config.sunCategory
}

export const isCategoryOptical = (category: string) => {
  return category === 'optical' || category === app_config.opticalCategory
}

export const isCategoryElectronicsOptical = (category: string) => {
  return (
    category === 'electronics-optical' ||
    category === 'ElectronicsOptical' ||
    category === app_config.electronicsOpticalCategory
  )
}

export const isCategoryElectronicsSun = (category: string) => {
  return (
    category === 'electronics-sun' ||
    category === 'ElectronicsSun' ||
    category === app_config.electronicsSunCategory
  )
}

export const isCategoryElectronics = (category: string) =>
  category === 'electronics' || category === app_config.electronicsCategory

export const isCategoryOpticalOrElectronicsOptical = (category: string) => {
  return isCategoryOptical(category) || isCategoryElectronicsOptical(category)
}

export const isCategorySunOrElectronicsSun = (category: string) => {
  return isCategorySun(category) || isCategoryElectronicsSun(category)
}

export const isSameCategoryWithElectronics = (category1: string, category2: string) => {
  const isCategory1Optical = isCategoryOpticalOrElectronicsOptical(category1)
  const isCategory2Optical = isCategoryOpticalOrElectronicsOptical(category2)
  const isCategory1Sun = isCategorySunOrElectronicsSun(category1)
  const isCategory2Sun = isCategorySunOrElectronicsSun(category2)
  return (isCategory1Optical && isCategory2Optical) || (isCategory1Sun && isCategory2Sun)
}

export const categoryToId = (category: string) => {
  if (isCategorySun(category)) {
    return app_config.sunCategory
  } else if (isCategoryOptical(category)) {
    return app_config.opticalCategory
  } else if (isCategoryElectronics(category)) {
    return app_config.electronicsCategory
  } else if (isCategoryElectronicsOptical(category)) {
    return app_config.electronicsOpticalCategory
  } else if (isCategoryElectronicsSun(category)) {
    return app_config.electronicsSunCategory
  } else {
    return undefined
  }
}

export const categoryIdToSlug = (category: string | undefined) => {
  if (!category) {
    return undefined
  }
  if (isCategorySun(category)) {
    return 'sun'
  }
  if (isCategoryElectronicsSun(category)) {
    return 'electronics-sun'
  }
  if (isCategoryOptical(category)) {
    return 'optical'
  }
  if (isCategoryElectronicsOptical(category)) {
    return 'electronics-optical'
  }
  if (isCategoryElectronics(category)) {
    return 'electronics'
  }
  return undefined
}

export const subBrandToCategoryIds = (subBrand: SubBrand) => {
  if (subBrand.electronic) {
    if (subBrand.sun && subBrand.optical) {
      return [app_config.electronicsOpticalCategory, app_config.electronicsSunCategory]
    }
    if (subBrand.sun) {
      return [app_config.electronicsSunCategory]
    }
    if (subBrand.optical) {
      return [app_config.electronicsOpticalCategory]
    }
    return [app_config.electronicsOpticalCategory, app_config.electronicsSunCategory] // fallback to both
  } else {
    if (subBrand.sun && subBrand.optical) {
      return [app_config.sunCategory, app_config.opticalCategory]
    }
    if (subBrand.optical) {
      return [app_config.opticalCategory]
    }
    return [app_config.sunCategory] // fallback to Sun for clipons too
  }
}

export const subBrandToCategoryIdCompleteCart = (subBrand: SubBrand, selectedCategory: string) => {
  if (subBrand.junior) {
    return undefined // Junior products should not be included in the Cart Recommendations
  }

  if (subBrand.sun && subBrand.optical) {
    //If sub brand is parent brand, it has both values set to true so it has to be included
    return selectedCategory
  }

  if (subBrand.electronic) {
    return subBrand.optical
      ? app_config.electronicsOpticalCategory
      : app_config.electronicsSunCategory
  }

  return subBrand.optical ? app_config.opticalCategory : app_config.sunCategory // fallback to Sun for clipons too
}

export const getCouvettesBrands = (couvettes: Product[] = []) =>
  uniq(couvettes.map(couvette => couvette.brandCode))

export const couvetteContainsUpc = (couvette: Product, upc: string) =>
  !!Object.values(couvette.mocos).find(moco =>
    Object.values(moco.sizes).find(size => size.upc === upc),
  )

export const getSingleProductHeight = () => {
  if (isIPadView()) return window.innerHeight * 0.21
  else if (window.innerHeight < breakpointsNoUnit.M) return window.innerHeight * 0.19
  return window.innerHeight * 0.16
}

export const addCheckForLongPress = (
  direction: 'right' | 'left',
  el: null,
  gridRef: MutableRefObject<any>,
  intervalReference: MutableRefObject<any>,
  clientWidth: number,
  clickHandler: { (): void; (): void; (): void; (): void; (): void },
) => {
  if (el) {
    intervalReference.current = true
    const clearFunc = () => {
      window.onmouseleave = null
      window.onmouseup = null
      window.ondragleave = null
      window.onblur = null
      if (intervalReference.current !== true) clearInterval(intervalReference.current)
      intervalReference.current = null
    }
    window.onmouseleave = clearFunc
    window.onmouseup = clearFunc
    window.ondragleave = clearFunc
    window.onblur = clearFunc

    setTimeout(() => {
      if (!intervalReference.current) {
        clearInterval(intervalReference.current)
        intervalReference.current = null
        clickHandler()
      } else {
        if (intervalReference.current && intervalReference.current !== true)
          clearInterval(intervalReference.current)
        intervalReference.current = setInterval(() => {
          if (direction === 'right') {
            gridRef.current._scrollingContainer.scrollLeft +=
              (clientWidth || gridRef.current._scrollingContainer.clientWidth) / 90 //half screen scroll for second
          } else if (direction === 'left') {
            gridRef.current._scrollingContainer.scrollLeft -=
              (clientWidth || gridRef.current._scrollingContainer.clientWidth) / 90 //half screen scroll for second
          }
        }, 16) // should be 60fps if it is triggered correctly
      }
    }, 200)
  }
}

export const generateMaterialLabelKey = (material: string) =>
  `Filters.item_frontmaterial_${material.toLowerCase().replace(/[^a-zA-Z]+/g, '_')}`

const PRODUCT_FILTER_FIELDS: Record<string, keyof QueryParams> = {
  category: 'category',
  tags: 'tags',
  gender: 'gender',
  size: 'size',
  ageGroup: 'ageGroup',
  frontMaterial: 'frontMaterial',
  frameShape: 'frameShape',
  frontFamilyColor: 'frontFamilyColor',
  brand_slug: 'brand',
  type: 'type',
  geoFit: 'geoFit',
  asianFit: 'asianFit',
  lensProperties: 'lensProperties',
  release: 'release',
  assortmentIntent: 'assortmentIntent',
}

export const getQueryParamsFromFilters = (
  isKidCategoryModeEnabled: boolean,
  filters: any,
  customerId: string,
  brands: { items: Brand[] },
  start?: string,
  event?: RcEvent,
) => {
  const queryParams: Partial<QueryParams> = {
    customerId,
    start,
    showFacet: true,
  }

  const activeFilters = getActiveFilters(filters)
  forOwn(activeFilters, (filter, key) => {
    if (key === 'segment') {
      return true
    }
    let activeOptionsIds = filter.active
    // Convert brands
    if (key === 'brand_slug') {
      let groups: string[][] = []
      activeOptionsIds.forEach((p: string) => {
        const matchingBrands = brands.items.filter((brand: Brand) => brand.slug === p)
        if (matchingBrands.length) {
          const brandGroups =
            createBrandsQueryParams(matchingBrands, isKidCategoryModeEnabled) || []
          groups = [...groups, brandGroups]
        }
      })
      activeOptionsIds = groups
    }
    if (key === 'release' && event?.releases && activeOptionsIds.includes('other')) {
      const eventReleases = event.releases.map(release => release.label).concat('other')
      const otherReleases = Object.keys(filter.counter).filter(
        (option: string) => !eventReleases.includes(option),
      )
      activeOptionsIds = activeOptionsIds
        .filter((release: string) => release.toLowerCase() !== 'other')
        .concat(otherReleases)
    }
    if (key === 'tags') {
      activeOptionsIds = activeOptionsIds.map((option: string) => option.toLowerCase())
    }
    queryParams[PRODUCT_FILTER_FIELDS[key]] = activeOptionsIds.join(',')
  })

  return queryParams as QueryParams
}
