import classnames from 'classnames'
import React, { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Route, RouteChildrenProps, Switch } from 'react-router-dom'

import ErrorBoundary from './components/Error/ErrorBoundary'
import PrivateRoute from './components/PrivateRoute'
import VideoSocket from './components/VideoSocket'
import devicesConfig from './config/devices'
import ChooseAssortmentPage from './containers/ChooseAssortmentPage'
import LoginPage from './containers/LoginPage'
import PostLoginPage from './containers/PostLoginPage'
import SelectCustomersPage from './containers/SelectCustomerPage'
import SelectEventsPage from './containers/SelectEventsPage'
import SettingsPage from './containers/SettingsPage'
import ExtraIPadView from './devices/ExtraIPadView'
import LedLeftView from './devices/LedLeftView'
import LedRightView from './devices/LedRightView'
import TableView from './devices/TableView'
import VTOView from './devices/VTOView'
import WallView from './devices/WallView'
import DigitalEvents from './DigitalEvents'
import useSyncRoomName from './hooks/useSyncRoomName'
import { getPageNameFromUrl } from './libs/url'
import { mainRoutes } from './model/model'
import { useDispatch, useSelector } from 'react-redux'
import viewportActions from './store/viewport/actions'
import Loading from './components/Loading'
import { appShowSpinner, deviceNameSelector } from './store/app/selectors'
import { initSmartlook } from './libs/smartlook'
import { isLocalhost } from './registerServiceWorker'
import Debug from './Debug'
import app_config from './config/app/config'

const BaseRoomView: React.FC<RouteChildrenProps> = ({ match }) => {
  return (
    <Fragment>
      <Switch>
        <PrivateRoute
          path={`${match?.url}/${devicesConfig.table.routes.root_url}`}
          component={TableView}
        />
        <Route path={`${match?.url}/${devicesConfig.wall.routes.root_url}`} component={WallView} />
        <Route
          path={`${match?.url}/${devicesConfig.ledleft.routes.root_url}`}
          component={LedLeftView}
        />
        <Route
          path={`${match?.url}/${devicesConfig.ledright.routes.root_url}`}
          component={LedRightView}
        />
      </Switch>
    </Fragment>
  )
}
const Base: React.FC<RouteChildrenProps> = ({ match }) => {
  useSyncRoomName()

  return (
    <VideoSocket>
      {devicesConfig.isCurrentDeviceAuthorized([
        devicesConfig.table,
        devicesConfig.extraipad,
        devicesConfig.vto,
      ]) ? (
        <Helmet>
          {/* Put analytics scripts here */}
          {/* <script src="/vendor/Javascipt_Hotjar_UAT_Redcarpet.js"></script> */}
          <body
            className={classnames({
              [`page-${getPageNameFromUrl()}`]: true,
            })}
          />
        </Helmet>
      ) : null}
      <ErrorBoundary>
        <Switch>
          <Route
            path={`${match?.url}/${devicesConfig.extraipad.routes.root_url}`}
            component={ExtraIPadView}
          />
          <Route path={`${match?.url}/${devicesConfig.vto.routes.root_url}`} component={VTOView} />
          <Route path={`${match?.url}/:type`} component={BaseRoomView} />
        </Switch>
      </ErrorBoundary>
    </VideoSocket>
  )
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const deviceName = useSelector(deviceNameSelector)
  const showSpinner = useSelector(appShowSpinner)

  useEffect(() => {
    if (deviceName === 'table') {
      initSmartlook()
    }
  }, [deviceName])

  useEffect(() => {
    const updateViewport = () => {
      dispatch(viewportActions.updateViewport())
    }
    window.addEventListener('resize', updateViewport)

    return () => {
      window.removeEventListener('resize', updateViewport)
    }
  }, [dispatch])

  return (
    <>
      {(isLocalhost || ['uat', 'dev'].includes(app_config.env)) && <Debug />}
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path={`/${mainRoutes.postLogin}`} component={PostLoginPage} />
        <Route exact path={`/${mainRoutes.settings}`} component={SettingsPage} />
        <PrivateRoute exact path={`/${mainRoutes.events}`} component={SelectEventsPage} />
        <PrivateRoute exact path={`/${mainRoutes.customers}`} component={SelectCustomersPage} />
        <PrivateRoute
          exact
          path={`/${mainRoutes.chooseAssortment}`}
          component={ChooseAssortmentPage}
        />
        <PrivateRoute path={`/${mainRoutes.digitalEvents}`} component={DigitalEvents} />
        <Route path="/:room" component={Base} />
      </Switch>
      {showSpinner && <Loading isFullPage={false} />}
    </>
  )
}

export default App
