import { FC, ReactNode } from 'react'
import { isPdpVisibleSelector } from '../../store/pdp/selectors'
import { useSelector } from 'react-redux'
import { openedBrandsSelector } from '../../store/brands/selectors'
import { plpFiltersSelector } from '../../store/filters/selectors'
import { useTranslation } from 'react-i18next'
import { sendClearSessionEvent } from '../../libs/errors'

import styled from 'styled-components/macro'
import { palette } from '../../style/theme'
import { customerIdSelector, errorsSelector, eventIdSelector } from '../../store/app/selectors'
import { tokenSelector } from '../../store/auth/selectors'
import { useHistory } from 'react-router-dom'
import { getDeviceAbsolutePath } from '../../libs/url'

const GlobalErrorStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: #222;
  color: #fff;
`

const GlobalErrorWrapperStyled = styled.div`
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 1em;
  text-align: center;
  margin-bottom: 2rem;
`

const Title = styled.h1`
  color: #fff;
`

const Body = styled.div`
  padding: 1em 0;
  word-break: break-all;
  overflow-y: scroll;
`

const ReloadButton = styled.button`
  margin-right: 1rem;
  background-color: ${palette.congressBlue};
  border-color: ${palette.congressBlue};
  border-radius: 2em;
  height: auto;
  padding: 0.5em 1em;
  min-width: 7em;
  color: white;
`

const BackToLoginButton = styled(ReloadButton)`
  background-color: white;
  border-color: white;
  color: black;
`

const chooseSmartlookCustomEvent = (options: any, message: string) => {
  const canOpenPDP =
    options.locationPath.includes('single-brand') ||
    options.locationPath.includes('wishlist') ||
    options.locationPath.includes('cart')

  const conditionCustomEvents = {
    login: options.token === null || options.token === '',
    eventCustomer: options.locationPath.includes('customers'),
    home: options.locationPath.includes('choose-brand'),
    plp:
      options.locationPath.includes('single-brand') &&
      options.openedBrands.length &&
      !options.pdpVisible &&
      options.isSelectedCategory,
    pdp: canOpenPDP && options.pdpVisible,
    cart: options.locationPath.includes('cart'),
    wishlist: options.locationPath.includes('wishlist'),
  }

  const [selectedSection] = Object.entries(conditionCustomEvents).find(([, value]) => value) || [
    'generic',
  ]
  const customEventName = `error-${selectedSection}`

  // eslint-disable-next-line no-console
  console.log(`Smartlook custom event: ${customEventName} > ${message}`)

  if (window.smartlook) {
    window.smartlook('track', customEventName, { message })
  }
}

interface GlobalErrorProps {
  children: ReactNode
}

export const GlobalError: FC<GlobalErrorProps> = ({ children }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const plpFilters = useSelector(plpFiltersSelector)
  const isPdpVisible = useSelector(isPdpVisibleSelector)
  const openedBrands = useSelector(openedBrandsSelector)
  const customerId = useSelector(customerIdSelector)
  const eventId = useSelector(eventIdSelector)
  const token = useSelector(tokenSelector)
  const errors = useSelector(errorsSelector)

  const thereAreInfoToReload = !!(token && eventId && customerId)

  const locationPath = window.location.pathname.split('/')
  const isSelectedCategory = plpFilters?.category?.active?.length > 0

  return errors.length ? (
    <GlobalErrorStyled>
      {errors.map((error, i) => {
        const { errorData, goToHome } = error

        const safeErrorData = {
          priority: 1,
          origin: 'JavaScript',
          message: error?.errorData?.message || '',
          stack: error?.errorData?.stack || '',
          ...errorData,
        }

        chooseSmartlookCustomEvent(
          {
            isPdpVisible,
            token,
            openedBrands,
            locationPath,
            isSelectedCategory,
          },
          safeErrorData.message,
        )

        return (
          <GlobalErrorWrapperStyled
            key={`${safeErrorData.code}_${i}`}
            className={['global-error-wrapper', `priority_${safeErrorData.priority}`].join(' ')}
            style={{ order: -safeErrorData.priority }}
          >
            <Title>{`${safeErrorData.origin} ${
              safeErrorData.status ? ` - ${safeErrorData.status}` : ''
            } - Something went wrong!`}</Title>
            {goToHome ? (
              <BackToLoginButton
                onClick={() => history.replace(`${getDeviceAbsolutePath()}/choose-brand`)}
              >
                {t('FooterNav.item_goback')}
              </BackToLoginButton>
            ) : (
              <div className="buttons">
                {thereAreInfoToReload && (
                  <ReloadButton onClick={() => window.location.reload()}>
                    {t('GenericWords.reload_page')}
                  </ReloadButton>
                )}
                <BackToLoginButton
                  className="back-login-button"
                  onClick={() => sendClearSessionEvent()}
                >
                  {t('User.backToLogin')}
                </BackToLoginButton>
              </div>
            )}
            <Body>
              <div>
                {safeErrorData.operationNamespace &&
                  `Operation: ${safeErrorData.operationNamespace}`}
              </div>
              {[
                [safeErrorData.code, 'Code'],
                [safeErrorData.stack, 'Stack'],
                [safeErrorData.message, 'Message'],
              ]
                .filter(([value]) => !!value)
                .map(([value, label]) => {
                  return (
                    <div key={label}>
                      <span>
                        <strong>{label}:</strong> {value}
                      </span>
                    </div>
                  )
                })}
            </Body>
          </GlobalErrorWrapperStyled>
        )
      })}
    </GlobalErrorStyled>
  ) : (
    <>{children}</>
  )
}
